<template>
  <p-container class="no-bg">
    <template slot="left" v-if="showLeft">
      <a-tree :show-line="true" :default-expanded-keys="['0-0']" :tree-data="treeData"></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button>-->
      <div>
        <a-spin tip="Loading..." :spinning="isLoading">
          <section class="upload-police-serach-container">
            <div style="display: flex; align-items: center">
              <div class="input-line">
                <div class="select-wrap">
                  请选择文件:
                  <a-select
                    label-in-value
                    :default-value="{ key: '基本情况' }"
                    style="width: 120px"
                    @change="handleChange"
                  >
                    <a-select-option value="基本情况">基本情况</a-select-option>
                    <a-select-option value="专利情况">专利情况</a-select-option>
                    <a-select-option value="完成单位">完成单位</a-select-option>
                    <a-select-option value="完成人">完成人</a-select-option>

                    <a-select-option value="经济效益">经济效益</a-select-option>
                    <a-select-option value="奖励情况">奖励情况</a-select-option>
                  </a-select>
                </div>
                <!-- multiple:是否支持多选；action：上传地址；method：请求方法；headers：设置上传的请求头部 -->
              </div>

              <div class="search-update">
                <a-upload
                  style="display: flex; margin: 5px"
                  name="file"
                  :multiple="false"
                  :action="uploadUrl"
                  method="post"
                  @change="uploadFile"
                >
                  <a-button type="primary">
                    <a-icon type="upload" />上传文件
                  </a-button>
                </a-upload>

                <a-input-search
                  placeholder="搜索"
                  enter-button="搜索"
                  size="default"
                  @search="onSearch"
                />
                <span class="result-num">成果库 {{ resultNum }} 项，搜索结果 {{ searchNum }} 项</span>
              </div>
            </div>
          </section>
        </a-spin>
      </div>

      <!-- <a-button type="primary">批量分类梳理</a-button>
      <a-button type="primary">申请号批量查询</a-button>
      <a-button type="primary">启动评审</a-button>-->
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
      :pagination="pagination"
    >
      <a
        href="javascript:;"
        @click="cxdShowModal(col)"
        slot="cxdAction"
        slot-scope="text, col"
      >{{ text }}</a>

      <span slot="wcrqkAction" slot-scope="text, col">
        <p
          @click="wcrqkShowModal(wcrmc, col.cgguid)"
          v-for="wcrmc in col.wcrname"
          :key="wcrmc"
          :class="classname"
        >{{ wcrmc }}</p>
      </span>
      <span slot="wcdwqkAction" slot-scope="text, col">
        <p
          href="javascript:;"
          @click="wcdwqkShowModal(dwmc, col.cgguid)"
          v-for="dwmc in col.dwmc"
          :key="dwmc"
          :class="classname"
        >{{ dwmc }}</p>
      </span>
      <span slot="zcxmqkAction" slot-scope="text, col">
        <p
          href="javascript:;"
          @click="zcxmqkShowModal(zcxmmc, col.cgguid)"
          v-for="zcxmmc in col.zcxmmc"
          :key="zcxmmc"
          :class="classname"
        >{{ zcxmmc }}</p>
      </span>
      <span slot="hjqkAction" slot-scope="text, col">
        <p
          href="javascript:;"
          @click="hjqkShowModal(hjxmmc, col.cgguid)"
          v-for="hjxmmc in col.hjxmmc"
          :key="hjxmmc"
          :class="classname"
        >{{ hjxmmc }}</p>
      </span>
      <span slot="zzqkAction" slot-scope="text, col">
        <p
          href="javascript:;"
          @click="zzqkShowModal(lb, col.cgguid)"
          v-for="lb in col.lb"
          :key="lb"
          :class="classname"
        >{{ lb }}</p>
      </span>
      <a
        href="javascript:;"
        @click="jjxyqkShowModal(col)"
        slot="jjxyqkAction"
        slot-scope="text, col"
      >经济效益</a>
    </a-table>
    <!--创新点-->
    <a-modal v-model="cxdVisible" title="创新点" @ok="handleOk">
      <div>
        <a-input-group>
          <a-row :gutter="20" :data-source="cxdText">
            <a-col :span="30">
              <a-form-item label>
                <a-textarea v-model="cxdText.cxd" @input="autoTextarea" :disabled="true" :span="30"></a-textarea>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" :data-source="cxdText">
            <a-col :span="30">
              <a-form-item label="验收鉴定意见">
                <a-textarea v-model="cxdText.ysyj" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" :data-source="cxdText">
            <a-col :span="30">
              <a-form-item label="核心技术指标">
                <a-textarea v-model="cxdText.jszb" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
      <div></div>
    </a-modal>
    <!--完成人情况-->
    <a-modal v-model="wcrqkVisible" title="完成人情况" @ok="handleOk">
      <div>
        <a-input-group>
          <a-row :gutter="2">
            <a-col :span="8">
              <a-form-item label="姓名">
                <a-input v-model="wcrText.name" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="出生年月">
                <a-input v-model="wcrText.csny" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="人才称号">
                <a-input v-model="wcrText.rcch" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="2">
            <a-col :span="12">
              <a-form-item label="职务">
                <a-input v-model="wcrText.zw" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="职称">
                <a-input v-model="wcrText.zc" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" :data-source="wcrText">
            <a-col :span="30">
              <a-form-item label="研究方向">
                <a-textarea v-model="wcrText.yjfx" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" :data-source="wcrText">
            <a-col :span="30">
              <a-form-item label="曾获国家奖励情况及排名">
                <a-textarea v-model="wcrText.gjjl" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
      <div></div>
    </a-modal>
    <!--完成单位情况-->
    <a-modal v-model="wcdwqkVisible" title="完成单位情况" @ok="handleOk">
      <div>
        <a-input-group>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="单位名称">
                <a-input v-model="wcdwText.dwmc" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="实验室建设">
                <a-input v-model="wcdwText.sysjs" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="单位类型">
                <a-input v-model="wcdwText.dwlx" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
      <div></div>
    </a-modal>
    <!--支持项目情况-->
    <a-modal v-model="zcxmqkVisible" title="支持项目情况" @ok="handleOk">
      <div>
        <a-input-group>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="支撑项目名称">
                <a-input v-model="zcxmqkText.zcxmmc" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="4">
            <a-col :span="24">
              <a-form-item label="项目来源">
                <a-input v-model="zcxmqkText.rwly" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="2">
            <a-col :span="12">
              <a-form-item label="项目起始时间">
                <a-input v-model="zcxmqkText.kssj" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="项目完成时间">
                <a-input v-model="zcxmqkText.wcsj" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
      <div></div>
    </a-modal>
    <!--获奖情况-->
    <a-modal v-model="hjqkVisible" title="获奖情况" @ok="handleOk()">
      <div>
        <a-input-group>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="获奖项目名称">
                <a-input v-model="hjqkText.hjxmmc" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="2">
            <a-col :span="8">
              <a-form-item label="级别">
                <a-input v-model="hjqkText.jb" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="获奖等级">
                <a-input v-model="hjqkText.hjdj" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="获奖年度">
                <a-input v-model="hjqkText.hjnd" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
    </a-modal>
    <!--专注-->
    <a-modal v-model="zzqkVisible" title="专著" @ok="handleOk">
      <div>
        <a-input-group>
          <a-row :gutter="20" :data-source="zzqkText">
            <a-col :span="30">
              <a-form-item label="日期">
                <a-textarea v-model="zzqkText.rq" @input="autoTextarea" :disabled="true" :span="30"></a-textarea>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" :data-source="zzqkText">
            <a-col :span="30">
              <a-form-item label="级别">
                <a-textarea v-model="zzqkText.jb" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" :data-source="cxdText">
            <a-col :span="30">
              <a-form-item label="专利/论文/标准/专著">
                <a-textarea v-model="zzqkText.lb" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
      <div></div>
    </a-modal>
    <!--经济效益-->
    <a-modal v-model="jjxyqkVisible" title="经济效益" @ok="handleOk()">
      <div>
        <a-input-group>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="年份">
                <a-input v-model="jjxyText.nf" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="2">
            <a-col :span="12">
              <a-form-item label="新增利润">
                <a-input v-model="jjxyText.xzlr" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="新增税收">
                <a-input v-model="jjxyText.xzss" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="创收外汇">
                <a-input v-model="jjxyText.cswh" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="节支总额">
                <a-input v-model="jjxyText.jyzz" :disabled="true" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="新增销售额">
                <a-input v-model="jjxyText.xzxse" :disabled="true" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
    </a-modal>
  </p-container>
</template>
<script>
// import func from '../../../../../vue-temp/vue-editor-bridge';
import {
  getDate,
  getcxd,
  getwcr,
  getwcdw,
  getzcxmqk,
  getzz,
  getjjxy,
  gethjqk,
  reqwest,
} from "../../../../api/result";
import { geturl } from "../../../../api/search";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
// TODO: 字段显示：年度，项目名称，奖种，完成单位，完成人
const columns = [
  {
    title: "年度",
    dataIndex: "cgnd",
    ellipsis: true,
    width: 120,
  },
  {
    title: "项目名称",
    dataIndex: "cgmc",
    ellipsis: true,
    width: 600
  },
  {
    title: "奖种",
    dataIndex: "jz",
    ellipsis: true,
    width: 180
  },
  {
    title: "完成人",
    dataIndex: "wcrname",
    scopedSlots: { customRender: "wcrqkAction" },
    ellipsis: true,
    width:180
  },
  {
    title: "完成单位",
    dataIndex: "dwmc",
    scopedSlots: { customRender: "wcdwqkAction" },
    ellipsis: true,
  },
];
const dataSource = [
  {
    cgmc: "cgmc",
  },
];
export default {
  name: "QuickSearch",
  data() {
    return {
      searchNum: 0,
      resultNum: 0,
      currentPage: 1,
      pagination: {
        total: 100,
        pageSize: 10,
        onChange: this.changePage,
      },
      searchQuery: "",
      loading: false,
      treeData: [],
      valuechoose: "",
      rowSelection,
      dataSource,
      columns,
      cxdVisible: false,
      wcrqkVisible: false,
      wcdwqkVisible: false,
      zcxmqkVisible: false,
      hjqkVisible: false,

      bzqkVisible: false,
      zzqkVisible: false,
      jjxyqkVisible: false,
      classname: "classname",
      cxdText: "",
      wcrText: "",
      wcdwText: "",
      zcxmqkText: "",
      hjqkText: "",
      zzqkText: "",
      jjxyText: "",
      uploading: false,
      isLoading: false,
      //是否禁用按钮
      btnState: true,
      //传给后台的列表数据
      list: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: "",
      //上传文件请求头
      headers: {
        authorization: "authorization-text",
      },
      data: {
        filetype: "基本情况",
      },
    };
  },
  mounted: function () {
    this.uploadUrl = geturl() + "/cgk/upload?filetype=基本情况";

    this.getTableApi(1);
  },
  computed: {
    type() {
      return this.$route.name.substr(this.$route.name.lastIndexOf("_") + 1);
    },
    // showLeft(){
    //   return this.type!='all'
    // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    changePage(page) {
      this.getTableApi(page);
    },
    // 获取分页数据
    async getTableApi(page) {
      this.currentPage = page;

      const res = await getDate({
        search: this.searchQuery,
        current: this.currentPage,
        size: this.pagination.pageSize,
      });
      const { data, total, size } = res.data;

      // TODO: 导入完成后，提示：导入成功多少项
      // this.$message.success(`成功导入 xxx 项`, 2.5)

      // TODO: 搜索按钮后面，增加文本显示，成果库多少项
      this.resultNum = total
      this.searchNum = size

      this.dataSource = data || this.dataSource;
      // this.$set(this.pagination, "total", res.data.size);
      this.pagination.total = total
    },
    initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    cxdShowModal(col) {
      this.cxdVisible = true;
      this.mdl = { ...col };

      getcxd({
        cgguid: col.cgguid,
      }).then((res) => {
        this.cxdText = res.data[0];
      });
    },
    wcrqkShowModal(mc, col) {
      this.wcrqkVisible = true;
      this.mdl = { ...col };
      getwcr({
        name: mc,
        cgguid: col,
      }).then((res) => {
        this.wcrText = res.data;
      });
    },
    wcdwqkShowModal(wcrmc, col) {
      this.wcdwqkVisible = true;
      this.mdl = { ...col };
      getwcdw({
        name: wcrmc,
        cgguid: col,
      }).then((res) => {
        this.wcdwText = res.data;
      });
    },
    zcxmqkShowModal(zcxmqk, col) {
      this.zcxmqkVisible = true;
      this.mdl = { ...col };
      getzcxmqk({
        name: zcxmqk,
        cgguid: col,
      }).then((res) => {
        this.zcxmqkText = res.data;
      });
    },
    hjqkShowModal(hjqk, col) {
      this.hjqkVisible = true;
      this.mdl = { ...col };
      gethjqk({
        name: hjqk,
        cgguid: col,
      }).then((res) => {
        this.hjqkText = res.data;
      });
    },
    bzqkShowModal(col) {
      this.bzqkVisible = true;
      this.mdl = { ...col };
    },
    zzqkShowModal(zzqk, col) {
      this.zzqkVisible = true;
      this.mdl = { ...col };
      getzz({
        name: zzqk,
        cgguid: col,
      }).then((res) => {
        this.zzqkText = res.data;
      });
    },
    jjxyqkShowModal(col) {
      this.jjxyqkVisible = true;
      this.mdl = { ...col };
      getjjxy({
        cgguid: col.cgguid,
      }).then((res) => {
        this.jjxyText = res.data;
      });
    },
    handleOk(e) {
      this.cxdVisible = false;
      this.wcrqkVisible = false;
      this.wcdwqkVisible = false;
      this.zcxmqkVisible = false;
      this.hjqkVisible = false;
      this.bzqkVisible = false;
      this.zzqkVisible = false;
      this.jjxyqkVisible = false;
    },
    onSearch(val) {
      this.searchQuery = val;
      this.getTableApi(1);
    },
    downloadExecl() {
      window.open(`https://xxxxx.com/xxxxxx`, "_blank");
    },
    //上传文件
    uploadFile(info, filelist, event) {
      this.fileList = info.fileList;
      // this.uploadData.splice(0)
      //如果没有数据
      if (info.file.response && info.file.response.data == null) {
        this.btnState = true;
      }
      // 有数据并且填写正确
      if (info.file.response && info.file.response.data != null) {
        this.btnState = false;
        // this.fileList.splice(0)
        info.file.response.data.forEach((item, index) => {
          //对列表数据转义赋值
          this.$set(this.uploadData, index, {
            key: index.toString(),
            sort: index + 1,
            id: item.userId,
            phone: item.mobile,
            name: item.name,
            type: item.type,
            count: item.number,
            paytime: `${item.beginData}-${item.endData}`,
          });
        });
        this.list = info.file.response.data;
        console.log(this.uploadData);
      } else if (
        info.file.response &&
        info.file.response.message != null &&
        this.fileList.length > 0
      ) {
        // 如果填写错误
        // console.log(info.file.response);
        this.$message.success(`导入成功，已累计导入${info.file.response.length}项`, 2.5)
        // // this.fileList.splice(0)
        // const h = this.$createElement;
        // this.$error({
        //   title: "提醒",
        //   content: h("div", {}, [
        //     h("p", `${info.file.response.message}`)
        //   ]),
        // });
      }
    },
    handleChange(value) {
      // { key: "lucy", label: "Lucy (101)" }
      this.valuechoose = value.key;

      this.uploadUrl =
        this.uploadUrl.substring(0, this.uploadUrl.lastIndexOf("=")) +
        "=" +
        this.valuechoose;
    },
  },
};
</script>

<style scoped>
.classname {
  color: #1da57a;
  cursor: pointer;
}
.ant-input-search {
  width: 340px;
}
.smileDark {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
}
.select-wrap {
  display: flex;
  align-items: center;
  width: 200px;
}
.ant-select {
  margin-left: 6px;
}
.search-update {
  display: flex;
  align-items: center;
  width: 100%;
}
.search-update .result-num {
  margin-left: 10px;
}
</style>
